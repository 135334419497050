import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

const StyledLink = ({ link, title }) => (
  <Link to={link}>
    <button className="inline-block mx-2 px-8 py-2 rounded-full border border-lime-600 text-lime-600 hover:bg-lime-600 hover:text-white max-w-max shadow-sm outline-none focus:outline-none hover:shadow-xl">
      {title}
    </button>
  </Link>
)

StyledLink.propTypes = {
  title: PropTypes.string,
}

StyledLink.defaultProps = {
  title: `Link`,
}

export default StyledLink
