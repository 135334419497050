import React from 'react'
import { connectSearchBox } from 'react-instantsearch-dom'

export default connectSearchBox(
  ({ refine, currentRefinement, className, onFocus }) => (
    <div class="group flex items-center text-left border rounded-full outline-none focus:outline-none hover:shadow-lg" type="button">
       <div class="flex items-center justify-center stroke-current stroke-2 overflow-visible dark:text-white transition duration-500 ease-in-out opacity-40 group-hover:opacity-90 relative h-8 w-8 rounded-full">
          <svg height="12" width="12" viewBox="0 0 32 32" style={{display: `block`}}>
              <g fill="none">
                  <path d="m13 24c6.0751322 0 11-4.9248678 11-11 0-6.07513225-4.9248678-11-11-11-6.07513225 0-11 4.92486775-11 11 0 6.0751322 4.92486775 11 11 11zm8-3 9 9"></path>
              </g>
          </svg>
        </div>
        <input
            className="outline-none focus:outline-none bg-transparent w-full mx-1 border-none text-left text-sm"
            type="text"
            placeholder="Search"
            aria-label="Search"
            onChange={e => refine(e.target.value)}
            value={currentRefinement}
            onFocus={onFocus}
          />
    </div>
  )
)