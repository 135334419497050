import React from 'react'
import { ThemeContext } from './themeContext'

export const ThemeToggle = () => {
  const { theme, setTheme } = React.useContext(ThemeContext)

  function isDark() {
    return theme === 'dark'
  }

  return (
    <>
      <div class="flex items-center justify-center w-full">
        <label for="themeToggleInput" class="flex items-center cursor-pointer">
          <div class="relative">
            <input id="themeToggleInput" type="checkbox" checked={isDark()} onChange={e => setTheme(e.target.checked ? 'dark' : 'light')} className="hidden" />
            {/* <div class="toggle__line w-10 h-4 bg-gray-400 rounded-full shadow-inner"></div>
            <div className="toggleDot absolute -top-1 -left-1 w-6 h-6 bg-white rounded-full shadow inset-y-0"></div> */}
            <div class="inline-block py-2 px-3 transition duration-500 ease-in-out bg-transparent text-gray-500 dark:text-white dark:hover:text-yellow-400 hover:bg-gray-200 hover:text-yellow-400 rounded-full relative outline-none focus:outline-none">
              <div class="flex items-center h-5">
                <div class="-mx-1">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" className={isDark() ? `hidden` : `block fill-current`} style={{height: `20px`, width: `20px`}}>
                    <path d="M17.293 13.293A8 8 0 016.707 2.707a8.001 8.001 0 1010.586 10.586z" />
                  </svg>
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" className={isDark() ? `block fill-current` : `hidden`} style={{height: `20px`, width: `20px`}}>
                    <path fillRule="evenodd" d="M10 2a1 1 0 011 1v1a1 1 0 11-2 0V3a1 1 0 011-1zm4 8a4 4 0 11-8 0 4 4 0 018 0zm-.464 4.95l.707.707a1 1 0 001.414-1.414l-.707-.707a1 1 0 00-1.414 1.414zm2.12-10.607a1 1 0 010 1.414l-.706.707a1 1 0 11-1.414-1.414l.707-.707a1 1 0 011.414 0zM17 11a1 1 0 100-2h-1a1 1 0 100 2h1zm-7 4a1 1 0 011 1v1a1 1 0 11-2 0v-1a1 1 0 011-1zM5.05 6.464A1 1 0 106.465 5.05l-.708-.707a1 1 0 00-1.414 1.414l.707.707zm1.414 8.486l-.707.707a1 1 0 01-1.414-1.414l.707-.707a1 1 0 011.414 1.414zM4 11a1 1 0 100-2H3a1 1 0 000 2h1z" clipRule="evenodd" />
                  </svg>
                </div>
              </div>
            </div>
          </div>
        </label>
      </div>
    </>
  )
}