import { default as React } from "react"
import StyledLink from "./styledLink"
import { Link } from "gatsby"
import { ThemeToggle } from "./themeToggle"
import Search from "./search"
import { ReactComponent as Logo } from '../images/svg/logo.svg'
const searchIndices = [{ name: `Recipes`, title: `Recipes` }]


const NavHeader = ({ siteTitle }) => (
<nav id="nav">

    <div id="logo" className="flex-initial">
        <Link aria-label="Go Home"  to="/">
          <div className="logo">
            <Logo />
          </div>
        </Link>
    </div>
    <div id="searchBar" class="flex-grow text-center">
      <Search indices={searchIndices} />
    </div>

    <div id="utilityBar" class="flex-initial">
      <div class="flex justify-end items-center relative">
        <div class="flex md:mr-2 items-center">
          <div className="hidden">
            <StyledLink className="inline-block py-2 px-3 hover:bg-gray-200 rounded-full" title="Pinned" link="#" />
          </div>
          {/* <div className="toggle mx-1">
            <WakeLock />
          </div> */}
          <div className="toggle mx-1">
            <ThemeToggle />
          </div>
        </div>
        <div class="hidden">
            <div class="inline relative">
                <button type="button" name="Login Menu Button" class="inline-flex items-center relative px-2 border dark:border-gray-500 dark:text-white rounded-full outline-none focus:outline-none hover:shadow-lg">
                    <div class="pl-1">
                        <svg viewBox="0 0 32 32" style={{display: `block`, fill: `none`, height: `16px`, width: `16px`, stroke: `currentcolor`, 'stroke-width': `3`, overflow: `visible`}}>
                            <g fill="none" fill-rule="nonzero">
                                <path d="m2 16h28"></path>
                                <path d="m2 24h28"></path>
                                <path d="m2 8h28"></path>
                            </g>
                        </svg>
                    </div>
                    <div class="block flex-grow-0 flex-shrink-0 h-10 w-12 pl-5">
                        <svg viewBox="0 0 32 32" style={{display: `block`, height: `100%`, width: `100%`, fill: `currentcolor`}}>
                            <path d="m16 .7c-8.437 0-15.3 6.863-15.3 15.3s6.863 15.3 15.3 15.3 15.3-6.863 15.3-15.3-6.863-15.3-15.3-15.3zm0 28c-4.021 0-7.605-1.884-9.933-4.81a12.425 12.425 0 0 1 6.451-4.4 6.507 6.507 0 0 1 -3.018-5.49c0-3.584 2.916-6.5 6.5-6.5s6.5 2.916 6.5 6.5a6.513 6.513 0 0 1 -3.019 5.491 12.42 12.42 0 0 1 6.452 4.4c-2.328 2.925-5.912 4.809-9.933 4.809z"></path>
                        </svg>
                    </div>
                </button>
            </div>
        </div>
      </div>
    </div>
</nav>
)

export default NavHeader
