import { Link } from "gatsby"
import { default as React } from "react"
import {
  connectStateResults,
  Highlight,
  Hits,
  Index,
  Snippet,
  PoweredBy,
} from "react-instantsearch-dom"
const HitCount = connectStateResults(({ searchResults }) => {
  const hitCount = searchResults && searchResults.nbHits
  return hitCount > 0 ? (
    <div className="flex justify-start text-xs py-4 dark:text-white opacity-70">
      {hitCount} result{hitCount !== 1 ? `s` : ``}
    </div>
  ) : null
})
const PageHit = ({ hit }) => (
  <div>
    <div className="my-1">
      <Link to={`/${hit.slug}`}>
        <div className="block">
          <h4 className="opacity-60 text-xs">
            <Highlight attribute="author" hit={hit} tagName="mark" />'s
          </h4>
          <h4 className="opacity-90 font-medium text-violet-700">
            <Highlight attribute="title" hit={hit} tagName="mark" />
          </h4>
        </div>
      </Link>
        
      <Snippet attribute="description" hit={hit} tagName="mark" />
    </div>
    <hr className="mt-2" />
  </div>
  
)
const HitsInIndex = ({ index }) => (
  <Index indexName={index.name}>
    <Hits className="ml-0 list-none text-sm dark:text-white" hitComponent={PageHit} />
    <HitCount />
  </Index>
)
const SearchResult = ({ indices, show }) => (
  <div className={show ? 'searchOverlay' : 'hidden'}>
    {indices.map(index => (
      <HitsInIndex index={index} key={index.name} />
    ))}
    <PoweredBy />
  </div>
)
export default SearchResult