import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import NavHeader from "./navHeader"
import { ThemeProvider } from "./themeContext"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)
  return (
    <>
      <ThemeProvider>
      <div className="flex flex-col h-screen">
        <NavHeader siteTitle={data.site.siteMetadata?.title || `Title`} />
        <main className="flex-1 overflow-y-auto">{children}</main>
      </div>
      </ThemeProvider>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
